import "./Post.css";
import Plank from "../Plank/Plank";
import verticalWood from "../../img/vertical-post-wood.png";
import wood from "../../img/plank-wood-small.png";
import {useEffect,useLayoutEffect, useRef, useState} from 'react';
import axios from 'axios';

export default function Post() {

    const [links, setLinks] = useState([]);

    const ref = useRef(null);

    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setHeight(ref.current.clientHeight);
        }, 100);
      
        return () => clearInterval(interval);
      }, []);

      useEffect(() => {
        axios.get('https://api.rrc.media/api/hp-links?populate=*&sort=Order:ASC')
          .then((res) => {
            let objs = res.data.data.map((obj) => {
              return { title: obj.attributes.Title, 
                       link: obj.attributes.Link,
                      }
            })
            setLinks([...objs])
          })
      }, [])

      let link_planks = links.map((link, idx) => {return <Plank key={idx} {...link}/>})

    return (
        <div className="post-wrapper" ref={ref}>
            <div className="vertical-post-sign" style={{ background:`url(${wood}), #BB855C` }}>
                <h1>hiding places</h1>
            </div>
            <div className="plank-wrapper">            
                {link_planks}
            </div>
            <div className="vertical-post" style={{ background:`url(${verticalWood}), #8B603F`, height: `${height}px` }}></div>
        </div>
    );
}